/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampLike = `mutation CreateCampLike($input: CreateCampLikeInput!) {
  createCampLike(input: $input) {
    id
    userId
    campId
  }
}
`;
export const updateCampLike = `mutation UpdateCampLike($input: UpdateCampLikeInput!) {
  updateCampLike(input: $input) {
    id
    userId
    campId
  }
}
`;
export const deleteCampLike = `mutation DeleteCampLike($input: DeleteCampLikeInput!) {
  deleteCampLike(input: $input) {
    id
    userId
    campId
  }
}
`;
export const createSessionFavorite = `mutation CreateSessionFavorite($input: CreateSessionFavoriteInput!) {
  createSessionFavorite(input: $input) {
    id
    userId
    sessionId
  }
}
`;
export const updateSessionFavorite = `mutation UpdateSessionFavorite($input: UpdateSessionFavoriteInput!) {
  updateSessionFavorite(input: $input) {
    id
    userId
    sessionId
  }
}
`;
export const deleteSessionFavorite = `mutation DeleteSessionFavorite($input: DeleteSessionFavoriteInput!) {
  deleteSessionFavorite(input: $input) {
    id
    userId
    sessionId
  }
}
`;
export const createCamper = `mutation CreateCamper($input: CreateCamperInput!) {
  createCamper(input: $input) {
    id
    userId
    name
    birthMonth
    birthYear
  }
}
`;
export const updateCamper = `mutation UpdateCamper($input: UpdateCamperInput!) {
  updateCamper(input: $input) {
    id
    userId
    name
    birthMonth
    birthYear
  }
}
`;
export const deleteCamper = `mutation DeleteCamper($input: DeleteCamperInput!) {
  deleteCamper(input: $input) {
    id
    userId
    name
    birthMonth
    birthYear
  }
}
`;
