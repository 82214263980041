/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCampLike = `query GetCampLike($id: ID!) {
  getCampLike(id: $id) {
    id
    userId
    campId
  }
}
`;
export const listCampLikes = `query ListCampLikes(
  $filter: ModelCampLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      campId
    }
    nextToken
  }
}
`;
export const getSessionFavorite = `query GetSessionFavorite($id: ID!) {
  getSessionFavorite(id: $id) {
    id
    userId
    sessionId
  }
}
`;
export const listSessionFavorites = `query ListSessionFavorites(
  $filter: ModelSessionFavoriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessionFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      sessionId
    }
    nextToken
  }
}
`;
export const getCamper = `query GetCamper($id: ID!) {
  getCamper(id: $id) {
    id
    userId
    name
    birthMonth
    birthYear
  }
}
`;
export const listCampers = `query ListCampers(
  $filter: TableCamperFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      name
      birthMonth
      birthYear
    }
    nextToken
  }
}
`;
