import * as yup from 'yup'
import { Alert, Button, Col, Form, InputGroup } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

class AuthenticationConfirmSignUp extends React.PureComponent {
  render () {
    return (
    <>
      <div className="offset-md-3 col-md-6 col-12 mb-5 py-2 grayBackground">
        <h3>Confirm Your Account</h3>
        <p>Thanks for signing up!  Please check your inbox for an email from <code>hello@simplesummers.com</code> and click the provided link in order to confirm your account.</p>
        <p>Already confirmed your account?  <a href="#" onClick={() => this.props.stateChangeCallback('SignIn')}>Sign in!</a></p>
      </div>
    </>
    )
  }
}

class AuthenticationSignUp extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      isValid: false,
      error: false
    }
  }

  signUp = async (username, email, password) => {
    this.setState({ error: false, isLoading: true })
    await Auth.signUp({ username, password, attributes: { email } })
      .then(response => {
        this.setState({ isLoading: false })
        this.props.stateChangeCallback('ConfirmSignUp')
      }).catch(err => {
        this.setState({ error: err.message, isLoading: false })
      })
  }

  schema = yup.object({
    username: yup
      .string()
      .required('Please enter your user name'),
    email: yup
      .string()
      .required('Please enter your email address')
      .email('Please enter a valid email address'),
    password: yup
      .string()
      .required('Please enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><':;|_~`^\][)(]).{8,}/,
        'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
      )
  });

  onSubmit = values => {
    this.signUp(values.username, values.email, values.password)
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  render () {
    const alert = (this.state.error) ? (
      <Alert variant="danger" onClose={() => this.setState({ error: false })} dismissible>
        { this.state.error }
      </Alert>
    ) : (<></>)

    return (<>
        <div className="offset-md-3 col-md-6 col-12 mb-5 py-2 grayBackground">
          <h3>Sign Up</h3>
          <p>Create an account now to fully customize your search results. Add one or more campers, then customize your search results based on each camper's age. Save camp sessions to your family schedule, then add each camp session to your preferred online calendar. Simple!</p>
          <p>Already have an account?  <a href="#" onClick={() => this.props.stateChangeCallback('SignIn')}>Sign in!</a></p>
          { alert }
          <Formik
            validationSchema={this.schema}
            initialValues={{
              username: '',
              email: '',
              password: '',
            }}
            onSubmit={this.onSubmit}
          >

            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formEmail">
                    <Form.Label>Email Address</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formSubmit">
                    <Form.Label>&nbsp;</Form.Label>
                    <InputGroup>
                      {
                        (this.state.isLoading) ? (
                          <Button variant="cta" type="submit" disabled>
                              Signing Up <FontAwesomeIcon icon={ faSyncAlt } spin />
                          </Button>
                        ) : (
                          <Button variant="cta" type="submit" disabled={!isValid}>
                              Sign Up
                          </Button>
                        )
                      }
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </Form>
            )}
          </Formik>
        </div>
      </>)
  }
}

class AuthenticationForgotPassword extends React.PureComponent {
    constructor (props) {
      super(props)

      this.state = {
        isLoading: false,
        isValid: false,
        error: false
      }
    }

    forgetPassword = async (username) => {
      this.setState({ error: false, isLoading: true })
      try {
        await Auth.forgotPassword(username)
        this.props.stateChangeCallback('ForgotPasswordSubmit', username)
      } catch (err) {
        this.setState({ isLoading: false })
        console.log(err)
        if (err.code === 'UserNotConfirmedException') {
          this.setState({ error: (<>Sorry, but it appears as though you haven't yet verified your account!  Our platform requires accounts to be verified before a user can be logged in for the sake of security.  Please check your inbox for an email from <code>hello@simplesummers.com</code> and follow the included confirmation link in order to be able to sign in.</>) })
        } else if (err.code === 'NotAuthorizedException') {
          this.setState({ error: (<>The password you entered is not correct.</>) })
        } else if (err.code === 'UserNotFoundException') {
          this.setState({ error: (<>There is no account for that username.</>) })
        } else if (err.code === 'LimitExceededException') {
          this.setState({ error: (<>Attempt limit exceeded, please try after some time.</>) })
        } else {
          this.setState({ error: (<>Sorry, but we encountered un unexpected error when trying to log you in.  The error code encountered was <code>{ err.code }</code>.  If this error persists, please <a href="mailto:hello@simplesummers.com?subject=Login Error { err.code }">contact us</a> and we'll try and help you through it.</>) })
        }
      }
    }

    onSubmit = values => {
      this.forgetPassword(values.username)
    }

    handleSubmit = event => {
      event.preventDefault()
      event.stopPropagation()
    }

    schema = yup.object({
      username: yup
        .string()
        .required('Please enter your user name')
    });

    render () {
      const alert = (this.state.error) ? (
        <Alert variant="danger" onClose={() => this.setState({ error: false })} dismissible>
          { this.state.error }
        </Alert>
      ) : (<></>)

      return (<>
        <div className="offset-md-3 col-md-6 col-12 mb-5 py-2 grayBackground">
          <h3>Forgot Password</h3>
          <p style={{width: "100%"}}>Fill out your username to receive a verification code in your email to continue the process of resetting your password.</p>
          <p>Don't have an account?  <a href="#" onClick={() => this.props.stateChangeCallback('SignUp')}>Sign up!</a></p>
          { alert }
          <Formik
            validationSchema={this.schema}
            initialValues={{
                username: this.props.username ? this.props.username : '',
            }}
            onSubmit={this.onSubmit}
          >

            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formSubmit">
                    <Form.Label>&nbsp;</Form.Label>
                    <InputGroup>
                      {
                        (this.state.isLoading) ? (
                          <Button variant="cta" type="submit" disabled>
                              Signing In <FontAwesomeIcon icon={ faSyncAlt } spin />
                          </Button>
                        ) : (
                          <Button variant="cta" type="submit" disabled={!isValid}>
                              Sign In
                          </Button>
                        )
                      }
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </Form>
            )}
          </Formik>
        </div>
      </>)
    }
}

class AuthenticationForgotPasswordSubmit extends React.PureComponent {
    constructor (props) {
      super(props)

      this.state = {
        isLoading: false,
        isValid: false,
        error: false
      }
    }

    forgetPasswordSubmit = async (username, code, new_password) => {
      this.setState({ error: false, isLoading: true })
      try {
        await Auth.forgotPasswordSubmit(username, code, new_password)
        window.location.reload(false)
      } catch (err) {
        this.setState({ isLoading: false })
        console.log(err)
        if (err.code === 'UserNotConfirmedException') {
          this.setState({ error: (<>Sorry, but it appears as though you haven't yet verified your account!  Our platform requires accounts to be verified before a user can be logged in for the sake of security.  Please check your inbox for an email from <code>hello@simplesummers.com</code> and follow the included confirmation link in order to be able to sign in.</>) })
        } else if (err.code === 'NotAuthorizedException') {
          this.setState({ error: (<>The password you entered is not correct.</>) })
        } else if (err.code === 'UserNotFoundException') {
          this.setState({ error: (<>There is no account for that username.</>) })
        } else if (err.code === 'InvalidPasswordException') {
          this.setState({ error: (<>Password does not conform to policy: Password must have uppercase characters</>) })
        } else {
          this.setState({ error: (<>Sorry, but we encountered un unexpected error when trying to log you in. The error code encountered was <code>{ err.code }</code>. If this error persists, please <a href="mailto:hello@simplesummers.com?subject=Login Error { err.code }">contact us</a> and we'll try and help you through it.</>) })
        }
      }
    }

    onSubmit = values => {
      this.forgetPasswordSubmit(values.username, values.code, values.password)
    }

    handleSubmit = event => {
      event.preventDefault()
      event.stopPropagation()
    }

    schema = yup.object({
      username: yup
        .string()
        .required('Please enter your user name'),
      code: yup
        .string()
        .required('Please enter the verification code that was sent to your email'),
      password: yup
        .string()
        .required('Please enter your password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><':;|_~`^\][)(]).{8,}/,
            'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
        )
    });

    render () {
      const alert = (this.state.error) ? (
        <Alert variant="danger" onClose={() => this.setState({ error: false })} dismissible>
          { this.state.error }
        </Alert>
      ) : (<></>)

      return (<>
        <div className="offset-md-3 col-md-6 col-12 mb-5 py-2 grayBackground">
            <h3>Forgot Password</h3>
            <p>The verification code has been sent to your email address. Copy it here and set your new password.</p>
            { alert }
            <Formik
            validationSchema={this.schema}
            initialValues={{
              username: this.props.username ? this.props.username : '',
              code: '',
              password: '',
            }}
            onSubmit={this.onSubmit}
          >

            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formCode">
                    <Form.Label>Code</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Code"
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                        isInvalid={!!errors.code}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.code}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formPassword">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formSubmit">
                    <Form.Label>&nbsp;</Form.Label>
                    <InputGroup>
                      {
                        (this.state.isLoading) ? (
                          <Button variant="cta" type="submit" disabled>
                              Signing In <FontAwesomeIcon icon={ faSyncAlt } spin />
                          </Button>
                        ) : (
                          <Button variant="cta" type="submit" disabled={!isValid}>
                              Sign In
                          </Button>
                        )
                      }
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </Form>
            )}
          </Formik>
        </div>
      </>)
    }
}

class AuthenticationSignIn extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      isValid: false,
      error: false
    }
  }

  signIn = async (username, password) => {
    this.setState({ error: false, isLoading: true })
    try {
      await Auth.signIn(username, password)
      window.location.reload(false)
    } catch (err) {
      this.setState({ isLoading: false })
      console.log(err)
      if (err.code === 'UserNotConfirmedException') {
        this.setState({ error: (<>Sorry, but it appears as though you haven't yet verified your account!  Our platform requires accounts to be verified before a user can be logged in for the sake of security.  Please check your inbox for an email from <code>hello@simplesummers.com</code> and follow the included confirmation link in order to be able to sign in.</>) })
      } else if (err.code === 'NotAuthorizedException') {
        this.setState({ error: (<>The password you entered is not correct.</>) })
      } else if (err.code === 'UserNotFoundException') {
        this.setState({ error: (<>There is no account for that username.</>) })
      } else {
        this.setState({ error: (<>Sorry, but we encountered un unexpected error when trying to log you in.  The error code encountered was <code>{ err.code }</code>.  If this error persists, please <a href="mailto:hello@simplesummers.com?subject=Login Error { err.code }">contact us</a> and we'll try and help you through it.</>) })
      }
    }
  }

  onSubmit = values => {
    this.signIn(values.username, values.password)
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  schema = yup.object({
    username: yup
      .string()
      .required('Please enter your user name'),
    password: yup
      .string()
      .required('Please enter your password')
  });

  render () {
    const alert = (this.state.error) ? (
      <Alert variant="danger" onClose={() => this.setState({ error: false })} dismissible>
        { this.state.error }
      </Alert>
    ) : (<></>)

    return (<>
        <div className="offset-md-3 col-md-6 col-12 mb-5 py-2 grayBackground">
          <h3>Sign In</h3>
          <p>Sign in now to access your account. View and/or edit your campers. Search more camps and add relevant camp sessions to your schedule. Then, add each camp session to your preferred online calendar. You are one step closer to a stress-free summer. It's simple!</p>
          <p>Don't have an account?  <a href="#" onClick={() => this.props.stateChangeCallback('SignUp')}>Sign up!</a></p>
          <p>Forgot your password?  <a href="#" onClick={() => this.props.stateChangeCallback('ForgotPassword' )}>Reset Password!</a></p>
          { alert }
          <Formik
            validationSchema={this.schema}
            initialValues={{
                username: this.props.username ? this.props.username : '',
                password: '',
            }}
            onSubmit={this.onSubmit}
          >

            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formSubmit">
                    <Form.Label>&nbsp;</Form.Label>
                    <InputGroup>
                      {
                        (this.state.isLoading) ? (
                          <Button variant="cta" type="submit" disabled>
                              Signing In <FontAwesomeIcon icon={ faSyncAlt } spin />
                          </Button>
                        ) : (
                          <Button variant="cta" type="submit" disabled={!isValid}>
                              Sign In
                          </Button>
                        )
                      }
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </Form>
            )}
          </Formik>
        </div>
      </>)
  }
}

class Authentication extends React.PureComponent {
    constructor (props) {
        super(props)

        this.state = {
            authenticationState: 'SignIn',
            username: '',
        }
    }

    stateChange = async (newAuthenticationState, username) => {
        this.setState({
            authenticationState: newAuthenticationState,
            username: username
        })
    }

    render () {
      if (this.state.authenticationState === 'SignIn') {
        return (<AuthenticationSignIn stateChangeCallback={ this.stateChange } username={ this.state.username } />)
      } else if (this.state.authenticationState === 'SignUp') {
        return (<AuthenticationSignUp stateChangeCallback={ this.stateChange } username={ this.state.username } />)
      } else if (this.state.authenticationState === 'ConfirmSignUp') {
        return (<AuthenticationConfirmSignUp stateChangeCallback={ this.stateChange } username={ this.state.username } />)
      } else if (this.state.authenticationState === 'ForgotPassword') {
        return (<AuthenticationForgotPassword stateChangeCallback={ this.stateChange } username={ this.state.username } />)
      } else if (this.state.authenticationState === 'ForgotPasswordSubmit') {
        return (<AuthenticationForgotPasswordSubmit stateChangeCallback={ this.stateChange } username={ this.state.username } />)
      }
    }
}

export default Authentication
