import { API, Auth, graphqlOperation } from 'aws-amplify'
import { Authenticator, Greetings } from 'aws-amplify-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createCamper, deleteCamper, deleteSessionFavorite, updateCamper } from '../graphql/mutations'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { graphql } from 'gatsby'
import { listCampers, listSessionFavorites } from '../graphql/queries'
import { v4 as uuid } from 'uuid'
import AddToCalendar from 'react-add-to-calendar'
import Layout from '../layouts/layout'
import React from 'react'
import SEO from '../components/seo'
import Authentication from '../components/Authentication'
const moment = require('moment')
const _ = require('lodash')
const { Camper, Camp } = require('../components/SimSumHelpers')

class CamperDetails extends React.PureComponent {
  render () {
    return (
      <div>
        <a href="#" onClick={() => this.props.editCamperCallback(this.props.camper)} >
          { this.props.camper.name } ({ Math.floor(this.props.camper.age()) } years old)
        </a>
      </div>
    )
  }
}

class CamperEdit extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      id: props.id,
      name: props.name,
      birthMonth: props.birthMonth ? props.birthMonth : 0,
      birthYear: props.birthYear ? props.birthYear : moment().format('YYYY')
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render () {
    const months = Array
      .from(Array(12), (x, index) => index)
      .map(index => ({ value: index, label: moment({ month: index }).format('MMMM') }))

    const years = Array
      .from(Array(18), (x, index) => index)
      .map(index => {
        const year = moment().subtract(index, 'years').format('YYYY')
        return { value: year, label: year }
      })

    const camper = new Camper({
      id: this.state.id,
      name: this.state.name,
      birthMonth: this.state.birthMonth,
      birthYear: this.state.birthYear,
    })

    const deleteButton = (camper.id) ? (<button onClick={() => this.props.deleteCamperCallback(camper)} className={`btn btn-light btn-sm mr-2`} >Delete</button>) : <></>

    const saveButton = <button onClick={() => this.props.editCamperCallback(camper)} className={`btn btn-light btn-sm mr-2`} >Save</button>

    const cancelButton = <button onClick={() => this.props.cancelEditCamperCallback()} className={`btn btn-light btn-sm mr-2`} >Cancel</button>

    return (
        <>
          <div className="coral container p-3 rounded">
            <div className="row">
              <div className="form-group col-12">
                <label htmlFor="name">First Name or Nickname</label>
                <input type="text" className="form-control" id="name" name="name" autocomplete placeholder="Brian, Skipper, Best Behaved, etc!" value={this.state.name} onChange={this.handleChange} required />
              </div>
              <div className="form-group col-6">
                <label htmlFor="birthMonth">Birth Month</label>
                <select type="text" className="form-control" id="birthMonth" name="birthMonth" placeholder="MM" value={this.state.birthMonth} onChange={this.handleChange} required>
                  { months.map((object, index) => (<option key={index} value={object.value}>{object.label}</option>)) }
                </select>
              </div>
              <div className="form-group col-6">
                <label htmlFor="birthYear">Birth Year</label>
                <select type="text" className="form-control" id="birthYear" name="birthYear" placeholder="YYYY" value={this.state.birthYear} onChange={this.handleChange} required>
                  { years.map((object, index) => (<option key={index} value={object.value}>{object.label}</option>)) }
                </select>
              </div>
              <div className="col-12">
                { saveButton }
                { cancelButton }
                { deleteButton }
              </div>
            </div>
          </div>
        </>
    )
  }
}

class SessionDetails extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <li className="list-group-item px-0">
        <div className="d-flex">
          <div className="flex-grow-1">
            <a href={this.props.session.campSlug}><span className="text-uppercase font-weight-bold">{ this.props.session.organizationName }</span><br />{ [this.props.session.campName, this.props.session.name].flatMap(o => o).join(', ') }</a><br /><span className=" summary font-italic">{ this.props.session.dates() }</span>
          </div>
          <div>
            <button onClick={() => this.props.deleteSessionFavoriteCallback(this.props.sessionFavoriteUUID)} className={`btn btn-link p-0 m-0 pl-3 pb-1 shadow-none`} >
              <FontAwesomeIcon icon={ faTimes } />
            </button>
          </div>
        </div>
        <div className="btn-link">
          <AddToCalendar event={this.props.session.calendarEvent()} buttonLabel="Add To Calendar" buttonTemplate={ { textOnly: 'none' }}/>
        </div>
      </li>
    )
  }
}

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
class AccountPageInterface extends React.PureComponent {
  updateCampers = async () => {
    await API.graphql(
      graphqlOperation(listCampers, { limit: 1024 })
    ).then(response => {
      const campers = response.data.listCampers.items.map(camper => new Camper(camper)).sort(this.sortByName)
      this.setState({ campers: campers, isLoading: false })
    }).catch(err => {
      console.log(err)
      this.setState({ campers: [], isLoading: false })
    })
  }

  updateSessionFavorites = async () => {
    await API.graphql(
      graphqlOperation(listSessionFavorites, { limit: 1024 })
    ).then(response => {
      this.setState({ sessionFavorites: response.data.listSessionFavorites.items })
    }).catch(err => {
      console.log(err)
      this.setState({ sessionFavorites: [] })
    })
  }

  componentDidMount = async () => {
    await Auth.currentAuthenticatedUser().then(response => {
      this.setState({ user: response })
    }).catch(err => {
      console.log(err)
      this.setState({ user: null })
    })

    this.updateCampers()
    this.updateSessionFavorites()
  }

  componentDidUpdate (prevProps) {
    if (this.props.authState !== prevProps.authState && this.props.authState === 'signedIn') {
      this.updateCampers()
      this.updateSessionFavorites()
    }
  }

  deleteSessionFavorite = async sessionFavoriteUUID => {
    await API.graphql(
      graphqlOperation(deleteSessionFavorite, {
        input: { id: sessionFavoriteUUID },
      })
    ).then(response => {
      this.setState({
        sessionFavorites: this.state.sessionFavorites.filter(favorite => favorite.id !== response.data.deleteSessionFavorite.id)
      })
    }).catch(err => {
      console.log(err)
    })
  }

  // signUp = async () => {
  //
  // }

  signOut = async () => {
    Auth.signOut().then(response => {
      console.log(response)
      this.setState({ user: response })
    }).catch(err => {
      console.log(err)
      this.setState({ user: null })
    })
  }

  deleteCamper = async camper => {
    await API.graphql(
      graphqlOperation(deleteCamper, {
        input: { id: camper.id },
      })
    ).then(response => {
      this.setState({
        isEditingCamper: false
      })
      this.updateCampers()
    }).catch(err => {
      console.log(err)
    })
  }

  editCamper = async camper => {
    await API.graphql(
      graphqlOperation(camper.id ? updateCamper : createCamper, {
        input: { id: camper.id ? camper.id : uuid(), name: camper.name, birthMonth: camper.birthMonth, birthYear: camper.birthYear },
      })
    ).then(response => {
      this.setState({
        isEditingCamper: false
      })
      this.updateCampers()
    }).catch(err => {
      console.log(err)
    })
  }

  editCamperInterface = camper => {
    this.setState({
      isEditingCamper: camper || new Camper({})
    })
  }

  cancelEditCamperInterface = () => {
    this.setState({
      isEditingCamper: false
    })
  }

  constructor (props) {
    super(props)

    this.state = {
      campers: [],
      isEditingCamper: false,
      isLoading: true
    }
  }

  sortByName = (lhs, rhs) => {
    lhs = lhs.name.toLowerCase()
    rhs = rhs.name.toLowerCase()
    return (lhs === rhs) ? 0 : (lhs > rhs) ? 1 : -1
  }

  render () {
    if (this.props.authState !== 'signedIn') {
      return (<Authentication />)
    }

    const sessions = this.props.data.allContentfulCamp.edges
      .filter(content => _.intersection(
        (this.state.sessionFavorites || []).map(favorite => favorite.sessionId),
        (content.node.sessions || []).map(session => session.contentful_id))
        .length > 0)
      .map(data => new Camp(data.node).sessions)
      .flat()

    let previousWeek = 0

    const sessionDetails = (this.state.sessionFavorites || [])
      .map(sessionFavorite => {
        const sessionIndex = _.indexOf(sessions.map(session => session.id), sessionFavorite.sessionId)
        const sessionDetail = (sessionIndex >= 0) ? sessions[sessionIndex] : null
        return (sessionDetail == null) ? null : { sessionFavorite, sessionDetail }
      })
      .filter(session => session !== null)
      .sort((lhs, rhs) => {
        lhs = lhs.sessionDetail.startDate
        rhs = rhs.sessionDetail.startDate
        return (lhs === rhs) ? 0 : (lhs > rhs) ? 1 : -1
      })
      .map(session => {
        const { sessionFavorite, sessionDetail } = session
        const elements = []
        const currentWeek = moment(sessionDetail.startDate).week()
        if (currentWeek !== previousWeek) {
          previousWeek = currentWeek
          const currentWeekDisplay = moment().week(currentWeek).day(1).format('[The week of] MMMM Do')
          elements.push((
            <li className="list-group-item px-0" key={ `${ sessionFavorite.id }-header` } >
              <h5 className="mb-0"> {currentWeekDisplay }</h5>
            </li>
          ))
        }
        elements.push(<SessionDetails session={ sessionDetail } key={ sessionFavorite.id } deleteSessionFavoriteCallback={ this.deleteSessionFavorite } sessionFavoriteUUID={sessionFavorite.id} />)
        return elements
      })

    const sessionHeader = ((this.state.sessionFavorites || []).length > 0) ? (<p>Below is your current family camp schedule. Love how it looks? Great! Print it out and/or add individual camp sessions to your online calendar. Looking for more camps?  <a href="/search/" className="text-dark">Search for more camps</a>. Decided a specific camp session doesn't fit your schedule? Delete it with the <FontAwesomeIcon icon={ faTimes } /> icon.</p>) : (<p>Oops. Looks like you haven't added any camp sessions to your schedule yet. <a href="/search/">Search all camps</a> now to start building your schedule.</p>)

    const campersHeader = ((this.state.campers || []).length > 0 ? (<p>Here are your current campers. All look good? Move on to view your current schedule. Need to add a camper? Click the button below.</p>) : (<p>Add at least one camper to customize search results. Have more than one camper? No problem. Add them all. You can toggle search results to show what's available for a single camper or view options for all campers at once.</p>))

    const camperDetails = (
      <>
        { this.state.campers.map((camper, index) => (<CamperDetails key={ index } camper={ camper } editCamperCallback={ this.editCamperInterface} cancelEditCamperCallback={this.cancelEditCamperInterface} />)) }
        <div className="pt-3"><button type="button" onClick={this.editCamperInterface} className={`btn btn-primary btn-sm`} >Add New Camper</button></div>
      </>
    )

    const camperEdit = (<CamperEdit id={ this.state.isEditingCamper.id } className="pb-3" name={ this.state.isEditingCamper.name } birthMonth={ this.state.isEditingCamper.birthMonth } birthYear={ this.state.isEditingCamper.birthYear } editCamperCallback={ this.editCamper } deleteCamperCallback={ this.deleteCamper } cancelEditCamperCallback={ this.cancelEditCamperInterface } />)

    return (
      <>
      <div className="offset-md-3 col-md-6 col-12 pb-5">
        <h3>My Family Camp Schedule</h3>
        <p>This is where your family camp schedule lives. You can add campers, view your current schedule, and delete or hide specific camp sessions. You can print out this schedule and/or add individual camp sessions to your online calendar.</p>
      </div>

      <div className={ `offset-md-3 col-md-6 col-12 pb-5 ${ (this.state.isLoading) ? 'd-none' : '' }` }>
        <h3>My Campers</h3>
        { campersHeader }
        { this.state.isEditingCamper ? camperEdit : camperDetails }
      </div>

      <div className="offset-md-3 col-md-6 col-12 pb-5">
        <h3>My Current Schedule</h3>
        { sessionHeader }
        <ul className="list-group list-group-flush">
          { sessionDetails }
        </ul>
      </div>

      <div className="offset-md-3 col-md-6 col-12 pb-5">
        <hr />
        <button type="button" onClick={this.signOut} className={`btn btn-warning btn-sm`} >Sign Out</button>
      </div>

      </>
    )
  }
}

const AccountPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title="Simple Summers - Account" />

      <div className="container">
        <div className="row">
          <Authenticator
            hideDefault={true}
          >
            <AccountPageInterface data={ data } />
          </Authenticator>
        </div>
      </div>
    </Layout>
  )
}

export default AccountPage

export const query = graphql`
  {
    allContentfulCamp (limit:1000) {
      edges {
        node {
          contentful_id
          name
          link
          organization {
            contentful_id
            name
          }
          sessions {
            contentful_id
            name
            minGrade
            maxGrade
            minAge
            maxAge
            price
            startDate
            endDate
            address {
              location {
                lat
                lon
              }
              city
            }
          }
        }
      }
    }
  }
`
